import Breadcrumbs from '@/components/Breadcrumbs';
import Btn from '@/components/Button';
import H5 from '@/components/H5';
import Loader, { LoaderStatus } from '@/components/Loader';
import React, { Fragment, ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ActionProps, Dialog } from '@/components/Dialog';
import SvgIcon from '@/components/SvgIcon';
import { FormErrors } from '@/hooks/useForm';
import { Product } from '@/model/Product';
import { SimpleStore } from '@/model/SimpleStore';
import { ProductDepartment } from '@/model/ProductDepartment';
import { Checklist } from '@/model/Checklist';
import { CollapseCustom } from '@/components/CollapseCustom';
import H3 from '@/components/H3';
import { Camera } from '@/model/Camera';
import { FromInputCamera, FromInputProduct, FromInputRegister, ShouldShowModal } from '../../types';
import { DepartmentContent } from '../../components/DepartmentContent';
import './styles.scss';
import { CameraContent } from '../../components/CameraContent';
import { ProductContent } from '../../components/ProductContent';

interface StateProps {
  status: LoaderStatus;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  stores: SimpleStore[];
  departments: ProductDepartment[];
  formDataRegister: FromInputRegister;
  formErrorsRegister: FormErrors<FromInputRegister>;
  registerDepartments: ProductDepartment[];
  checklists: Checklist[];
  formDataCamera: FromInputCamera;
  formErrorsCamera: FormErrors<FromInputCamera>;
  cameras: Camera[];
  executionTimes: string[];
  formDataProduct: FromInputProduct;
  formErrorsProduct: FormErrors<FromInputProduct>;
  products: Product[];
  registerProducts: Product[];
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    storeId,
    departmentId,
    cameraId,
    cameraName,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    storeId?: string;
    departmentId?: string;
    cameraId?: string;
    cameraName?: string;
  }) => void;
  onClearFilter: () => void;
  onFilter: () => void;
  onChangeInputRegister: <N extends keyof FromInputRegister, V>(name: N, value: V) => void;
  onAddRegisterDepartments: () => void;
  onChangeRegisterDepartments: (index: number, departmentId: string) => void;
  onRemoveRegisterDepartment: (index: number) => void;
  onSave: () => void;
  onShowDeleteDepartment: (storeId: string, departmentId: string) => void;
  onChangeInputCamera: <N extends keyof FromInputCamera, V>(name: N, value: V) => void;
  onAddRegisterCamera: () => void;
  onChangeRegisterCameras: (index: number, executionTime: string) => void;
  onRemoveRegisterCamera: (index: number) => void;
  onSaveCamera: () => void;
  onShowDeleteCamera: (storeId: string, departmentId: string, cameraId: string) => void;
  onChangeInputProduct: <N extends keyof FromInputProduct, V>(name: N, value: V) => void;
  onAddRegisterProduct: () => void;
  onChangeRegisterProducts: (index: number, productId: string) => void;
  onRemoveRegisterProduct: (index: number) => void;
  onSaveProduct: () => void;
  onShowDeleteProduct: (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ) => void;
}

type Props = StateProps & DispatchProps;

export const ConfigureChecklistUI: React.FC<Props> = ({
  status,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onShouldShowModal,
  onClearFilter,
  onFilter,
  stores,
  departments,
  formDataRegister,
  formErrorsRegister,
  onChangeInputRegister,
  registerDepartments,
  onAddRegisterDepartments,
  onChangeRegisterDepartments,
  onRemoveRegisterDepartment,
  onSave,
  checklists,
  onShowDeleteDepartment,
  formDataCamera,
  formErrorsCamera,
  onChangeInputCamera,
  cameras,
  executionTimes,
  onAddRegisterCamera,
  onChangeRegisterCameras,
  onRemoveRegisterCamera,
  onSaveCamera,
  onShowDeleteCamera,
  formDataProduct,
  formErrorsProduct,
  onChangeInputProduct,
  products,
  registerProducts,
  onAddRegisterProduct,
  onChangeRegisterProducts,
  onRemoveRegisterProduct,
  onSaveProduct,
  onShowDeleteProduct,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };

  const renderActionDialogToCancelRegister: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'secondary',
  };

  const renderActionDialogToRegister: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSave(),
    theme: 'primary',
  };

  const renderActionDialogToRegisterCamera: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSaveCamera(),
    theme: 'primary',
  };

  const renderActionDialogToRegisterProduct: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSaveProduct(),
    theme: 'primary',
  };

  const isDark = (): boolean => {
    let b = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            b = true;
            break;
          }
        }
      }
    }
    return b;
  };
  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Configurar Checklist"
        parent="Soluções"
        subParent="Checklist"
        title="Configurar"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.FILTER ? 'right' : 'center'}
        isContentWithCard={false}
        actions={[
          {
            [ShouldShowModal.FILTER]: renderActionDialogToCancelFilter,
            [ShouldShowModal.DEPARTMENT]: renderActionDialogToCancelRegister,
            [ShouldShowModal.CAMERA]: renderActionDialogToCancelRegister,
            [ShouldShowModal.PRODUCT]: renderActionDialogToCancelRegister,
          }[shouldShowModal],
          {
            [ShouldShowModal.FILTER]: renderActionDialogToFilter,
            [ShouldShowModal.DEPARTMENT]: renderActionDialogToRegister,
            [ShouldShowModal.CAMERA]: renderActionDialogToRegisterCamera,
            [ShouldShowModal.PRODUCT]: renderActionDialogToRegisterProduct,
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.FILTER]: (
              <DepartmentContent
                stores={stores}
                formData={formDataRegister}
                formErrors={formErrorsRegister}
                onChangeInput={onChangeInputRegister}
                departments={departments}
                registerDepartments={registerDepartments}
                onAddRegisterDepartments={onAddRegisterDepartments}
                onChangeRegisterDepartments={onChangeRegisterDepartments}
                onRemoveRegisterDepartment={onRemoveRegisterDepartment}
              />
            ),
            [ShouldShowModal.DEPARTMENT]: (
              <DepartmentContent
                stores={stores}
                formData={formDataRegister}
                formErrors={formErrorsRegister}
                onChangeInput={onChangeInputRegister}
                departments={departments}
                registerDepartments={registerDepartments}
                onAddRegisterDepartments={onAddRegisterDepartments}
                onChangeRegisterDepartments={onChangeRegisterDepartments}
                onRemoveRegisterDepartment={onRemoveRegisterDepartment}
              />
            ),
            [ShouldShowModal.CAMERA]: (
              <CameraContent
                formData={formDataCamera}
                formErrors={formErrorsCamera}
                onChangeInput={onChangeInputCamera}
                cameras={cameras}
                executionTimes={executionTimes}
                onAdd={onAddRegisterCamera}
                onChange={onChangeRegisterCameras}
                onRemove={onRemoveRegisterCamera}
              />
            ),
            [ShouldShowModal.PRODUCT]: (
              <ProductContent
                formData={formDataProduct}
                formErrors={formErrorsProduct}
                onChangeInput={onChangeInputProduct}
                products={products}
                registerProducts={registerProducts}
                onAdd={onAddRegisterProduct}
                onChange={onChangeRegisterProducts}
                onRemove={onRemoveRegisterProduct}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>Configurações</H5>
          </div>
          <div className="button-filter-container">
            <Btn
              size="md"
              attrBtn={{
                onClick: (): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.DEPARTMENT,
                    newTitleModal: 'Cadastrar',
                  });
                },
              }}
            >
              + Cadastrar
            </Btn>
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.FILTER,
                    newTitleModal: 'Filtrar',
                  });
                }}
              >
                <SvgIcon
                  iconId={isDark() ? 'fill-filter' : 'stroke-filter'}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
          </div>
        </div>
        {checklists && checklists.length > 0 ? (
          <Fragment>
            {checklists.map((checklist, index) => (
              <Fragment key={`checklist-${index}`}>
                <Row>
                  <Col>
                    <H3>{checklist.store.name}</H3>
                  </Col>
                </Row>
                <CollapseCustom
                  className="tree-card"
                  title={
                    <>
                      <SvgIcon
                        iconId={isDark() ? 'fill-checklist' : 'stroke-checklist'}
                        style={{ width: '20px', height: '20px' }}
                      />
                      Departamentos
                    </>
                  }
                >
                  <Row>
                    <Col>
                      <p className="text-title-gruop">Nome do depatamento</p>
                    </Col>
                  </Row>
                  <Row className="tree-container">
                    {checklist.departments &&
                      checklist.departments.length > 0 &&
                      checklist.departments.map((department, ixD) => (
                        <Col key={`department-${ixD}`} className="tree-item-container">
                          <div className="d-flex">
                            <div className="d-flex justify-content-between text-gruop tree-main-text">
                              <div className="mv-auto">{department.name}</div>
                              <div className="d-flex icon-content mv-auto">
                                <SvgIcon
                                  iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                                  className="mr-4 svg-icon action-icon"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                  }}
                                  onClick={(): void =>
                                    onShowDeleteDepartment(checklist.store.id, department.id)
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-success-link-widht">
                              <a
                                className="text-success-link"
                                onClick={(): void =>
                                  onShouldShowModal({
                                    value: ShouldShowModal.CAMERA,
                                    newTitleModal: `${department.name}`,
                                    storeId: checklist.store.id,
                                    departmentId: department.id,
                                  })
                                }
                              >
                                + cadastrar nova câmera
                              </a>
                            </div>
                          </div>
                          {department.cameras && department.cameras.length > 0 && (
                            <div className="tree">
                              <ul>
                                {department.cameras.map((camera, ixC) => (
                                  <li key={`camera-${ixC}`}>
                                    <div>{camera.description}</div>
                                    <div className="flex-shrink-0 ml-2">
                                      <SvgIcon
                                        iconId={isDark() ? 'fill-pen' : 'stroke-pen'}
                                        className="mr-4 svg-icon action-icon"
                                        style={{
                                          marginRight: '4px',
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        onClick={(): void =>
                                          onShouldShowModal({
                                            value: ShouldShowModal.CAMERA,
                                            newTitleModal: `${department.name}`,
                                            storeId: checklist.store.id,
                                            departmentId: department.id,
                                            cameraId: camera.id,
                                          })
                                        }
                                      />
                                      <SvgIcon
                                        iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                                        className="mr-4 svg-icon action-icon"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        onClick={(): void =>
                                          onShowDeleteCamera(
                                            checklist.store.id,
                                            department.id,
                                            camera.id,
                                          )
                                        }
                                      />
                                      <div
                                        className="text-success-link-widht"
                                        style={{ display: 'inline' }}
                                      >
                                        <a
                                          className="text-success-link"
                                          onClick={(): void =>
                                            onShouldShowModal({
                                              value: ShouldShowModal.PRODUCT,
                                              newTitleModal: `${department.name}`,
                                              storeId: checklist.store.id,
                                              departmentId: department.id,
                                              cameraId: camera.id,
                                              cameraName: camera.description,
                                            })
                                          }
                                        >
                                          + novo produto
                                        </a>
                                      </div>
                                      {camera.products && camera.products.length > 0 && (
                                        <div className="tree">
                                          <ul>
                                            {camera.products.map((product, ixP) => (
                                              <li key={`${ixP}-product-${product.id}`}>
                                                <div>{product.name}</div>
                                                <div className="flex-shrink-0 ml-2">
                                                  <SvgIcon
                                                    iconId={isDark() ? 'fill-pen' : 'stroke-pen'}
                                                    className="mr-4 svg-icon action-icon"
                                                    style={{
                                                      marginRight: '4px',
                                                      width: '20px',
                                                      height: '20px',
                                                    }}
                                                    onClick={(): void =>
                                                      onShouldShowModal({
                                                        value: ShouldShowModal.PRODUCT,
                                                        newTitleModal: `${department.name}`,
                                                        storeId: checklist.store.id,
                                                        departmentId: department.id,
                                                        cameraId: camera.id,
                                                        cameraName: camera.description,
                                                      })
                                                    }
                                                  />
                                                  <SvgIcon
                                                    iconId={
                                                      isDark() ? 'fill-trash' : 'stroke-trash'
                                                    }
                                                    className="mr-4 svg-icon action-icon"
                                                    style={{
                                                      width: '20px',
                                                      height: '20px',
                                                    }}
                                                    onClick={(): void =>
                                                      onShowDeleteProduct(
                                                        checklist.store.id,
                                                        department.id,
                                                        camera.id,
                                                        product.id,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Col>
                      ))}
                  </Row>
                </CollapseCustom>
              </Fragment>
            ))}
          </Fragment>
        ) : (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <H5 className="m-0">Sem dados para exibir</H5>
          </div>
        )}
      </Container>
    </Fragment>
  );
};
