import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { useDialog } from '@/hooks/useDialog';
import * as validators from '@/utils/validators';
import { useForm } from '@/hooks/useForm';
import { SimpleStore } from '@/model/SimpleStore';
import { getStores } from '@/feature/user/services';
import { AxiosError } from 'axios';
import { CustomError } from '@/model/CustomError';
import { getErrorMessage } from '@/utils/errorMessage';
import { toast } from 'react-toastify';
import { ProductDepartment } from '@/model/ProductDepartment';
import { Checklist } from '@/model/Checklist';
import { Camera } from '@/model/Camera';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { Product } from '@/model/Product';
import dayjs from 'dayjs';
import { ChecklistCamera } from '@/model/ChecklistCamera';
import { FromInputCamera, FromInputProduct, FromInputRegister, ShouldShowModal } from '../types';
import { ConfigureChecklistUI } from './ui';
import {
  getCameras,
  getChecklist,
  getChecklists,
  getDepartments,
  getProducts,
  getRegisterCamera,
  getRegisterProducts,
  removeCamera,
  removeChecklist,
  removeProduct,
  saveCamera,
  saveChecklist,
  saveProducts,
} from '../services';
import { DeleteContent } from '../components/DeleteContent';

export const ConfigureChecklistScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.FILTER);
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [stores, setStores] = useState<SimpleStore[]>([]);
  const [departments, setDepartments] = useState<ProductDepartment[]>([]);
  const [registerDepartments, setRegisterDepartments] = useState<ProductDepartment[]>([]);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [executionTimes, setExecutionTimes] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [registerProducts, setRegisterProducts] = useState<Product[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const confirmDelete = useConfirmDelete();

  const handleOnGetChecklists = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklists();
      setChecklists(response);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<FromInputRegister>({
    initialData: {
      storeId: '',
    },
    validators: {
      storeId: [validators.required],
    },
  });

  const {
    formData: formDataCamera,
    formErrors: formErrorsCamera,
    onChangeInput: onChangeInputCamera,
    isFormValid: isFormValidCamera,
    resetForm: resetFormCamera,
  } = useForm<FromInputCamera>({
    initialData: {
      storeId: '',
      storeName: '',
      departmentId: '',
      departmentName: '',
      cameraId: '',
    },
    validators: {
      storeId: [validators.required],
      storeName: [validators.required],
      departmentId: [validators.required],
      departmentName: [validators.required],
      cameraId: [validators.required],
    },
  });

  const {
    formData: formDataProduct,
    formErrors: formErrorsProduct,
    onChangeInput: onChangeInputProduct,
    isFormValid: isFormValidProduct,
    resetForm: resetFormProduct,
  } = useForm<FromInputProduct>({
    initialData: {
      storeId: '',
      storeName: '',
      departmentId: '',
      departmentName: '',
      cameraId: '',
      cameraName: '',
    },
    validators: {
      storeId: [validators.required],
      storeName: [validators.required],
      departmentId: [validators.required],
      departmentName: [validators.required],
      cameraId: [validators.required],
      cameraName: [validators.required],
    },
  });

  const handleOnGetDepartments = async (): Promise<void> => {
    const response = await getDepartments();
    setDepartments(response);
  };

  const handleOnGetStores = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getStores();
      setStores(response);
      await handleOnGetDepartments();
      if (response && response.length > 0) {
        if (response.length === 1) {
          onChangeInputRegister('storeId', response[0].id);
        }
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetChecklist = async (storeId: string, departmentId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklist(storeId, departmentId);
      onChangeInputRegister('storeId', response.store.id);
      setRegisterDepartments(response.departments);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetCameras = async (
    storeId: string,
    departmentId: string,
    cameraId?: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const department = await getChecklist(storeId, departmentId);
      onChangeInputCamera('storeId', department.store.id);
      onChangeInputCamera('storeName', department.store.name);
      onChangeInputCamera('departmentId', department.departments[0].id);
      onChangeInputCamera('departmentName', department.departments[0].name);
      const response = await getCameras(storeId, departmentId);
      setCameras(response);

      if (cameraId) {
        const reponseRegister = await getRegisterCamera(storeId, departmentId, cameraId);
        onChangeInputCamera('cameraId', reponseRegister.id);
        if (reponseRegister.executionTimes && reponseRegister.executionTimes.length > 0) {
          const newExecutionTimes: string[] = [];
          reponseRegister.executionTimes.forEach(data => {
            const strDate = data as unknown as string;
            newExecutionTimes.push(strDate.substring(0, 5));
          });
          setExecutionTimes(newExecutionTimes);
        } else {
          setExecutionTimes(['']);
        }
      } else {
        onChangeInputCamera('cameraId', '');
        setExecutionTimes(['']);
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetProducts = async (
    storeId: string,
    departmentId: string,
    cameraId: string,
    cameraName: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const department = await getChecklist(storeId, departmentId);
      onChangeInputProduct('storeId', department.store.id);
      onChangeInputProduct('storeName', department.store.name);
      onChangeInputProduct('departmentId', department.departments[0].id);
      onChangeInputProduct('departmentName', department.departments[0].name);
      onChangeInputProduct('cameraId', cameraId);
      onChangeInputProduct('cameraName', cameraName);
      const response = await getProducts(storeId, departmentId, cameraId);
      setProducts(response);
      const reponseRegister = await getRegisterProducts(storeId, departmentId, cameraId);
      if (reponseRegister.length === 0) {
        setRegisterProducts([{} as Product]);
      } else {
        setRegisterProducts(reponseRegister);
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    storeId,
    departmentId,
    cameraId,
    cameraName,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    storeId?: string;
    departmentId?: string;
    cameraId?: string;
    cameraName?: string;
  }): Promise<void> => {
    await handleOnGetStores();
    if (value === ShouldShowModal.DEPARTMENT && storeId && departmentId) {
      await handleOnGetChecklist(storeId, departmentId);
    } else if (value === ShouldShowModal.DEPARTMENT) {
      setRegisterDepartments([{} as ProductDepartment]);
    } else if (value === ShouldShowModal.CAMERA && storeId && departmentId) {
      await handleOnGetCameras(storeId, departmentId, cameraId);
    } else if (
      value === ShouldShowModal.PRODUCT &&
      storeId &&
      departmentId &&
      cameraId &&
      cameraName
    ) {
      await handleOnGetProducts(storeId, departmentId, cameraId, cameraName);
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    onToggle();
  };

  const handleOnAddRegisterDepartments = (): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    const newRegisterDepartment = {} as ProductDepartment;
    registerDepartments.forEach(data => {
      newRegisterDepartments.push(data);
    });
    newRegisterDepartments.push(newRegisterDepartment);
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnChangeRegisterDepartments = (index: number, departmentId: string): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    registerDepartments.forEach((data, i) => {
      if (i === index) {
        newRegisterDepartments.push({ id: departmentId } as ProductDepartment);
      } else {
        newRegisterDepartments.push(data);
      }
    });
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnRemoveRegisterDepartment = (index: number): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    registerDepartments.forEach((data, i) => {
      if (i !== index) {
        newRegisterDepartments.push(data);
      }
    });
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister()) {
      if (!registerDepartments || registerDepartments.length === 0) {
        toast.warn('Informar pelo menos um departamento!');
      } else if (registerDepartments && registerDepartments.length > 0) {
        let existswithoutId = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < registerDepartments.length; i++) {
          if (!registerDepartments[i] || !registerDepartments[i].id) {
            existswithoutId = true;
            break;
          }
        }
        if (existswithoutId) {
          toast.warn('Existe registro sem departamento selecionado!');
        } else {
          try {
            setStatus(LoaderStatus.LOADING);
            const payload: Checklist = {
              store: { id: formDataRegister.storeId } as SimpleStore,
              departments: registerDepartments,
            };
            await saveChecklist(payload);
            await handleOnGetChecklists();
            resetFormRegister();
            onToggle();
          } catch (error) {
            const err = error as AxiosError<CustomError>;
            toast.error(getErrorMessage(err));
          } finally {
            setStatus(LoaderStatus.DEFAULT);
          }
        }
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnRemoveChecklist = async (storeId: string, departmentId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      await removeChecklist(storeId, departmentId);
      await handleOnGetChecklists();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteDepartment = (storeId: string, departmentId: string): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnRemoveChecklist(storeId, departmentId),
        },
      ],
    });
  };

  const handleOnAddRegisterCamera = (): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach(data => {
      newExecutionTimes.push(data);
    });
    newExecutionTimes.push('');
    setExecutionTimes(newExecutionTimes);
  };

  const handleOnChangeRegisterCameras = (index: number, executionTime: string): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach((data, i) => {
      if (i === index) {
        newExecutionTimes.push(executionTime);
      } else {
        newExecutionTimes.push(data);
      }
    });
    setExecutionTimes(newExecutionTimes);
  };

  const handleOnRemoveRegisterCamera = (index: number): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach((data, i) => {
      if (i !== index) {
        newExecutionTimes.push(data);
      }
    });
    setExecutionTimes(newExecutionTimes);
  };

  const handleOnSaveCamera = async (): Promise<void> => {
    if (isFormValidCamera()) {
      const times: Date[] = [];
      const payload = {
        id: formDataCamera.cameraId,
        executionTimes: times,
      } as ChecklistCamera;
      let existswithoutId = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < executionTimes.length; i++) {
        if (!executionTimes[i]) {
          existswithoutId = true;
          break;
        } else {
          payload.executionTimes.push(
            dayjs(`2024-01-01 ${executionTimes[i]}:00`, 'DD/MM/YYYY HH:mm:ss').toDate(),
          );
        }
      }
      if (existswithoutId) {
        toast.warn('Existe registro sem camera selecionado!');
      } else {
        try {
          setStatus(LoaderStatus.LOADING);
          await saveCamera(formDataCamera.storeId, formDataCamera.departmentId, payload);
          await handleOnGetChecklists();
          resetFormCamera();
          onToggle();
        } catch (error) {
          const err = error as AxiosError<CustomError>;
          toast.error(getErrorMessage(err));
        } finally {
          setStatus(LoaderStatus.DEFAULT);
        }
      }
    }
  };

  const handleOnRemoveCamera = async (
    storeId: string,
    departmentId: string,
    cameraId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      await removeCamera(storeId, departmentId, cameraId);
      await handleOnGetChecklists();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteCamera = (
    storeId: string,
    departmentId: string,
    cameraId: string,
  ): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnRemoveCamera(storeId, departmentId, cameraId),
        },
      ],
    });
  };

  const handleOnAddRegisterProduct = (): void => {
    const newRegisterProducts: Product[] = [];
    const newRegisterProduct = {} as Product;
    registerProducts.forEach(data => {
      newRegisterProducts.push(data);
    });
    newRegisterProducts.push(newRegisterProduct);
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnChangeRegisterProducts = (index: number, productId: string): void => {
    const newRegisterProducts: Product[] = [];
    registerProducts.forEach((data, i) => {
      if (i === index) {
        newRegisterProducts.push({ id: productId } as Product);
      } else {
        newRegisterProducts.push(data);
      }
    });
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnRemoveRegisterProduct = (index: number): void => {
    const newRegisterProducts: Product[] = [];
    registerProducts.forEach((data, i) => {
      if (i !== index) {
        newRegisterProducts.push(data);
      }
    });
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnSaveProduct = async (): Promise<void> => {
    if (isFormValidProduct()) {
      let existswithoutId = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < registerProducts.length; i++) {
        if (!registerProducts[i] || !registerProducts[i].id) {
          existswithoutId = true;
          break;
        }
      }
      if (existswithoutId) {
        toast.warn('Existe registro sem produto selecionado!');
      } else {
        try {
          setStatus(LoaderStatus.LOADING);
          await saveProducts(
            formDataProduct.storeId,
            formDataProduct.departmentId,
            formDataProduct.cameraId,
            registerProducts,
          );
          await handleOnGetChecklists();
          resetFormProduct();
          onToggle();
        } catch (error) {
          const err = error as AxiosError<CustomError>;
          toast.error(getErrorMessage(err));
        } finally {
          setStatus(LoaderStatus.DEFAULT);
        }
      }
    }
  };

  const handleOnRemoveProduct = async (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      await removeProduct(storeId, departmentId, cameraId, productId);
      await handleOnGetChecklists();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteProduct = (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> =>
            handleOnRemoveProduct(storeId, departmentId, cameraId, productId),
        },
      ],
    });
  };

  useEffect(() => {
    handleOnGetChecklists();
  }, []);
  return (
    <ConfigureChecklistUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      stores={stores}
      departments={departments}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeInputRegister={onChangeInputRegister}
      registerDepartments={registerDepartments}
      onAddRegisterDepartments={handleOnAddRegisterDepartments}
      onChangeRegisterDepartments={handleOnChangeRegisterDepartments}
      onRemoveRegisterDepartment={handleOnRemoveRegisterDepartment}
      onSave={handleOnSave}
      onShowDeleteDepartment={handleOnShowDeleteDepartment}
      checklists={checklists}
      formDataCamera={formDataCamera}
      formErrorsCamera={formErrorsCamera}
      onChangeInputCamera={onChangeInputCamera}
      cameras={cameras}
      executionTimes={executionTimes}
      onAddRegisterCamera={handleOnAddRegisterCamera}
      onChangeRegisterCameras={handleOnChangeRegisterCameras}
      onRemoveRegisterCamera={handleOnRemoveRegisterCamera}
      onSaveCamera={handleOnSaveCamera}
      onShowDeleteCamera={handleOnShowDeleteCamera}
      formDataProduct={formDataProduct}
      formErrorsProduct={formErrorsProduct}
      onChangeInputProduct={onChangeInputProduct}
      products={products}
      registerProducts={registerProducts}
      onAddRegisterProduct={handleOnAddRegisterProduct}
      onChangeRegisterProducts={handleOnChangeRegisterProducts}
      onRemoveRegisterProduct={handleOnRemoveRegisterProduct}
      onSaveProduct={handleOnSaveProduct}
      onShowDeleteProduct={handleOnShowDeleteProduct}
    />
  );
};
