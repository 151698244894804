import { SelectAutoComplete } from '@/components/Select';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import Btn from '@/components/Button';
import { Camera } from '@/model/Camera';
import { InputText } from '@/components/InputText';
import { FromInputCamera } from '../../types';

interface StateProps {
  formData: FromInputCamera;
  formErrors: FormErrors<FromInputCamera>;
  cameras: Camera[];
  executionTimes: string[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FromInputCamera, V>(name: N, value: V) => void;
  onAdd: () => void;
  onChange: (index: number, executionTime: string) => void;
  onRemove: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const CameraContent: React.FC<Props> = ({
  formData,
  onChangeInput,
  formErrors,
  cameras,
  executionTimes,
  onAdd,
  onChange,
  onRemove,
}): JSX.Element => {
  const camerasOptions =
    cameras && cameras.length > 0
      ? cameras.map(data => ({
          label: data.description,
          value: data.id,
        }))
      : [];

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="storeName"
              label="Loja"
              placeholder="Nome do usuário"
              value={formData.storeName}
              onChange={e => onChangeInput('storeName', e.target.value)}
              error={formErrors.storeName && formErrors.storeName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="departmentName"
              label="Departamento"
              placeholder="Nome do usuário"
              value={formData.departmentName}
              onChange={e => onChangeInput('departmentName', e.target.value)}
              error={formErrors.departmentName && formErrors.departmentName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Camera"
              options={camerasOptions}
              name="cameraId"
              placeholder="Selecione a camera"
              defaultValue={formData.cameraId}
              onChange={value => onChangeInput('cameraId', value)}
              error={formErrors.cameraId && formErrors.cameraId[0]}
            />
          </FormGroup>
        </Col>
      </Row>

      {executionTimes &&
        executionTimes.length > 0 &&
        executionTimes.map((data, index) => (
          <Row key={`${index}-${data}`}>
            <Col md={6}>
              <InputText
                name={`${index}-executionTime-${data}`}
                label=""
                placeholder="Horário de execução"
                type="time"
                value={data}
                onChange={e => onChange(index, e.target.value)}
              />
            </Col>
            <Col md={6}>
              {index === 0 ? (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onAdd();
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  +
                </Btn>
              ) : (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onRemove(index);
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  -
                </Btn>
              )}
            </Col>
          </Row>
        ))}
    </Fragment>
  );
};
