import { SelectAutoComplete } from '@/components/Select';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import Btn from '@/components/Button';
import { InputText } from '@/components/InputText';
import { Product } from '@/model/Product';
import { FromInputProduct } from '../../types';

interface StateProps {
  formData: FromInputProduct;
  formErrors: FormErrors<FromInputProduct>;
  products: Product[];
  registerProducts: Product[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FromInputProduct, V>(name: N, value: V) => void;
  onAdd: () => void;
  onChange: (index: number, cameraId: string) => void;
  onRemove: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const ProductContent: React.FC<Props> = ({
  formData,
  onChangeInput,
  formErrors,
  products,
  registerProducts,
  onAdd,
  onChange,
  onRemove,
}): JSX.Element => {
  const productsOptions =
    products && products.length > 0
      ? products.map(data => ({
          label: `${data.barcode} - ${data.name}`,
          value: data.id,
        }))
      : [];

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="storeName"
              label="Loja"
              placeholder="Nome da loja"
              value={formData.storeName}
              onChange={e => onChangeInput('storeName', e.target.value)}
              error={formErrors.storeName && formErrors.storeName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="departmentName"
              label="Departamento"
              placeholder="Nome do departamento"
              value={formData.departmentName}
              onChange={e => onChangeInput('departmentName', e.target.value)}
              error={formErrors.departmentName && formErrors.departmentName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="cameraName"
              label="Camera"
              placeholder="Nome da camera"
              value={formData.cameraName}
              onChange={e => onChangeInput('cameraName', e.target.value)}
              error={formErrors.cameraName && formErrors.cameraName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>

      {registerProducts &&
        registerProducts.length > 0 &&
        registerProducts.map((data, index) => (
          <Row key={`${index}-${data.id}`}>
            <Col md={6}>
              <SelectAutoComplete
                label=""
                options={productsOptions}
                name={`product-${index}`}
                placeholder="Selecione o produto"
                defaultValue={data.id}
                onChange={value => onChange(index, value)}
                error={formErrors.storeId && formErrors.storeId[0]}
              />
            </Col>
            <Col md={6}>
              {index === 0 ? (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onAdd();
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  +
                </Btn>
              ) : (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onRemove(index);
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  -
                </Btn>
              )}
            </Col>
          </Row>
        ))}
    </Fragment>
  );
};
