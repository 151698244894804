// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  FILTER,
  DEPARTMENT,
  CAMERA,
  PRODUCT,
}

export interface FromInputRegister {
  storeId: string;
}

export interface FromInputCamera {
  storeId: string;
  storeName: string;
  departmentId: string;
  departmentName: string;
  cameraId: string;
}

export interface FromInputProduct {
  storeId: string;
  storeName: string;
  departmentId: string;
  departmentName: string;
  cameraId: string;
  cameraName: string;
}
