import { Camera } from '@/model/Camera';
import { Checklist } from '@/model/Checklist';
import { ChecklistCamera } from '@/model/ChecklistCamera';
import { Product } from '@/model/Product';
import { ProductDepartment } from '@/model/ProductDepartment';
import api from '@/services/api';

export const getChecklists = async (): Promise<Checklist[]> => {
  const { data } = await api.get<Checklist[]>('/checklist/v1');
  return data;
};

export const getStoreChecklists = async (storeId: string): Promise<Checklist[]> => {
  const { data } = await api.get<Checklist[]>(`/checklist/v1/${storeId}`);
  return data;
};

export const getDepartments = async (): Promise<ProductDepartment[]> => {
  const { data } = await api.get<ProductDepartment[]>('/product-department/v1/all');
  return data;
};

export const saveChecklist = async (checklist: Checklist): Promise<Checklist> => {
  const { data } = await api.post<Checklist>('/checklist/v1', checklist);
  return data;
};

export const getChecklist = async (storeId: string, departmentId: string): Promise<Checklist> => {
  const { data } = await api.get<Checklist>(
    `/checklist/v1/store/${storeId}/department/${departmentId}`,
  );
  return data;
};

export const removeChecklist = async (storeId: string, departmentId: string): Promise<void> => {
  await api.delete(`/checklist/v1/store/${storeId}/department/${departmentId}`);
};

export const getCameras = async (storeId: string, departmentId: string): Promise<Camera[]> => {
  const { data } = await api.get<Camera[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera`,
  );
  return data;
};

export const getRegisterCamera = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
): Promise<ChecklistCamera> => {
  const { data } = await api.get<ChecklistCamera>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/register/camera/${cameraId}`,
  );
  return data;
};

export const saveCamera = async (
  storeId: string,
  departmentId: string,
  payload: ChecklistCamera,
): Promise<ChecklistCamera> => {
  const { data } = await api.post<ChecklistCamera>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera`,
    payload,
  );
  return data;
};

export const removeCamera = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
): Promise<void> => {
  await api.delete<void>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera/${cameraId}`,
  );
};

export const getProducts = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
): Promise<Product[]> => {
  const { data } = await api.get<Product[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera/${cameraId}/product`,
  );
  return data;
};

export const getRegisterProducts = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
): Promise<Product[]> => {
  const { data } = await api.get<Product[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera/${cameraId}/product/register`,
  );
  return data;
};

export const saveProducts = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
  payload: Product[],
): Promise<Product[]> => {
  const { data } = await api.post<Product[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera/${cameraId}`,
    payload,
  );
  return data;
};

export const removeProduct = async (
  storeId: string,
  departmentId: string,
  cameraId: string,
  productId: string,
): Promise<void> => {
  await api.delete<void>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera/${cameraId}/product/${productId}`,
  );
};
